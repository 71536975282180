#main_logo {
  shape-image-threshold: inherit;
  object-fit: cover;
  width: 70px;
  height: 70px;
  margin-top: -.2em;
  padding-top: -3px;
}

.mainpriceconainter {
  margin-top: 110px;
}

.priceimage {
  width: 40vh;
  height: 30vh;
}

#pricecontainer {
  background-image: url("https://i.postimg.cc/9DKV48Hd/SUSBCRIPTION-NEPAL.png");
  background-position: center;
  background-size: cover;
  flex-wrap: wrap;
  display: flex;
  position: relative;
}

#pricecontainer:before {
  content: "";
  z-index: -1;
  background-color: #ffffff80;
  position: absolute;
  inset: 0;
}

.pricecontainer {
  border: 2px;
  margin: 7px;
  padding: 3px;
}

.pirceimg {
  width: 30%;
  height: 90vh;
}

.priceinfo {
  color: #000;
}

.categroyimage {
  height: 500px;
}

.PriceLoneContainer {
  background-color: #abb2b9;
  flex-wrap: wrap;
  margin-top: 25px;
  display: flex;
}

.objimg {
  width: 50vw;
  height: 500px;
}

.monthnumber {
  color: red;
  background-color: #fff;
  border: 3px solid #000;
  margin-top: 15px;
  margin-right: 100px;
  padding: 15px 25px;
  font-size: larger;
}

.monthnumber:hover {
  background-color: #abb2b9;
  border: 3.5px solid #000;
}

.devicenumber {
  margin-right: 100px;
  list-style-type: none;
}

.monthul, .radioclass {
  display: flex;
}

.radios {
  border-bottom-color: #00f;
  margin: 0 10px 0 30px;
}

.d-block {
  height: 600px;
}

#carousell {
  height: 520px;
}

.carousel-indicators {
  top: 500px;
}

@media (width <= 480px) {
  .d-block {
    height: 300px;
  }

  .objimg {
    width: 25em;
    height: 25em;
    padding-top: 35px;
  }

  .monthnumber {
    margin-top: 15px;
    margin-right: 40px;
    padding: 15px 25px;
  }

  .carousel-indicators {
    top: 280px;
  }

  .carousel-inner {
    overflow: unset !important;
    height: 250px !important;
  }
}
/*# sourceMappingURL=index.044ab3ac.css.map */
